<template>
	<mw-dialog v-model="dialog" cancel-btn>
		<template v-slot:button>
			<slot name="button" :click="() => (dialog = true)"></slot>
		</template>
		<template v-slot:dialog-body>
			<p>Sending message: {{ sms }}</p>
			<v-textarea
				v-model="body"
				label="Custom text to send instead of template"
			></v-textarea>
			<slot name="dialog-body"></slot>
		</template>
		<template v-slot:dialog-buttons>
			<v-btn color="success" @click="sendTest" :disabled="!authPhone">
				Send Test
			</v-btn>
			<v-btn color="primary" class="mr-2" @click="sendMessage(message)">
				Send
			</v-btn>
		</template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "SendMessageButton",
	props: {
		to: { type: String }, // to - id of user overrides default user in values
		sms: { type: String }, /// the name of the message in the messages store module,
		values: { type: Object, default: () => ({}) }, // values - keyed ids e.g. user, appointment etc
	},
	data: () => {
		return {
			dialog: false,
			body: null,
		};
	},
	computed: {
		auth() {
			return this.$store.state.auth.data;
		},
		authPhone() {
			return this.auth.phone;
		},
		message() {
			let message = {
				sms: this.sms,
				values: this.values,
			};
			if (this.to) {
				message.to = this.to;
			}
			if (this.body) {
				message.body = this.body;
			}
			return message;
		},
	},
	components: {
		MwDialog,
	},
	methods: {
		sendTest() {
			let message = this.message;
			message.to = this.auth.phone;
			this.sendMessage(message);
		},
		sendMessage(message) {
			const self = this;

			self.$store
				.dispatch("messages/sendOut", message)
				.then(() => {
					self.$root.$emit("showToast", "Sent");
					self.dialog = false;
				})
				.catch((e) => {
					self.dialog = false;
					self.$root.$emit("showToast", e, true);
				});
		},
	},
};
</script>
