<template>
	<mw-dialog v-model="dialog" cancel-btn>
		<template v-slot:button>
			<slot name="button" :click="() => (dialog = true)"></slot>
		</template>
		<template v-slot:dialog-body>
			<p>Sending message: {{email}}</p>
			<slot name="dialog-body"></slot>
		</template>
		<template v-slot:dialog-buttons>
			<v-btn color="success" @click="sendTest">Send Test</v-btn>
			<v-btn color="primary" class="mr-2" @click="sendEmail(message)"
				>Send</v-btn
			>
		</template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "SendEmailButton",
	props: {
		to: { type: String }, // to - id of user overrides default user in values
		template: { type: String }, // template name - overrides default template in emails store module
		email: { type: String }, /// the name of the email in the emails store module
		values: { type: Object, default: () => ({}) }, // values - keyed ids e.g. user, appointment etc
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		auth() {
			return this.$store.state.auth.data;
		},
		message() {
			let message = {
				email: this.email,
				values: this.values,
			};
			if (this.to) {
				message.to = this.to;
			}
			if (this.template) {
				message.template = this.template;
			}
			return message;
		},
	},
	components: {
		MwDialog,
	},
	methods: {
		sendTest() {
			let message = {...this.message};
			let id = this.auth.id;
			message.to = id;
			this.sendEmail(message);
		},
		sendEmail(message) {
			const self = this;

			self.$store.dispatch("emails/sendOut", message).then(() => {
				self.$root.$emit("showToast", "Sent");
				self.$emit("sent");
				self.dialog = false;
			}).catch( (e) => {
				self.dialog = false;
				self.$root.$emit("showToast", e, true );

			})
		},
	},
};
</script>
