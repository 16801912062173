<template>
	<mw-dialog v-model="dialog" large submit-btn @submit="submit">
		<template v-slot:button>
			<v-btn
				block
				class="mb-2"
				:color="value ? 'primary' : 'success'"
				:text="value ? true : false"
				:outlined="value ? true : false"
				@click="dialog = true"
			>
				<v-icon class="pr-1">
					{{ value ? "mdi-check" : "mdi-alert" }}</v-icon
				>
				{{ value ? "Change" : "Select" }} Screener
			</v-btn>
		</template>
		<template v-slot:dialog-body>
            <v-select
                v-model="survey"
                label="Select screener"
                item-value="id"
                item-text="internal_name"
                :return-object="false"
                :items="surveys"
            ></v-select>
			<response-table
				:value="responses"
				:questions="questions"
				:show-export="false"
				:show-select="false"
				:show-prepend="true"
				@click="(v) => (selected = v)"
			>
				<template v-slot:prepend="{ item }">
					<v-icon v-if="item.id == selected" color="green"
						>mdi-check-circle</v-icon
					>
				</template>
			</response-table>
		</template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
import ResponseTable from "@c/responses/ResponseTable";
export default {
	name: "SelectResponseForAppointment",
	props: {
		value: { type: String },
		user: { type: String },
		screeners: { type: [Array] },
	},
	data: () => {
		return {
			selected: null,
			dialog: false,
			survey: null,
		};
	},
	computed: {
		surveys() {
			const self = this;
			return self.screeners.map((s) => self.$store.state.surveys.data[s]).filter( a => a);
		},
		questions() {
			const self = this;
			let survey = this.$store.state.surveys.data[this.survey];
			if (!survey) {
				return [];
			}
			let questions = survey.questions;
			return questions.filter((q) => {
				let question = self.$store.state.questions.data[q];
				return question && question.type !== "html";
			});
		},
		responses() {
			const self = this;
			let responses = self.$store.state.surveyResponses.data;
			if (!responses) {
				return [];
			}
			responses = Object.values(responses);

			return responses
				.filter((response) => {
					return (
						response.participant == self.user &&
						response.survey == self.survey
					);
				})
				.map((m) => m.id);
		},
	},
	methods: {
		submit() {
			this.$emit("input", this.selected);
		},
	},
	components: {
		ResponseTable,
		MwDialog,
	},
	created() {
		const self = this;
        self.selected = self.value;
        self.survey = self.screeners[0];
        self.screeners.forEach( survey => {
            self.$store.dispatch("surveys/fetchById", survey);
            self.$store.dispatch("surveyResponses/fetchBySurvey", survey);
        })
	},
};
</script>
