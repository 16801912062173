<template>
	<mw-dialog v-model="dialog">
		<template v-slot:button>
			<v-btn text outlined color="primary" block @click="dialog = true">
				<v-icon class="pr-1"> mdi-message </v-icon>
				Send Messages
			</v-btn>
		</template>
		<template v-slot:dialog-body>
			<v-row v-for="message in messages" :key="message.template">
				<v-col>
					<p class="subtitle-2 mb-0">
						{{ message.name }}
					</p>
					<!-- <small>
						<router-link
							class="mr-2"
							v-if="message.email_template"
							target="_blank"
							:to="`/templates/${message.email_template}`"
							>Edit Email Template</router-link
						>
						<router-link
							class="mr-2"
							v-if="message.sms_template"
							target="_blank"
							:to="`/templates/${message.sms_template}`"
							>Edit SMS Template</router-link
						>
					</small> -->
				</v-col>
				<v-col class="text-right">
					<send-email-button
						class="mb-5"
						:values="values"
						:email="message.email"
						v-if="message.email"
					>
						<template v-slot:button="scope">
							<v-btn
								@click="scope.click"
								icon
							>
								<v-icon>mdi-email-arrow-right</v-icon>
							</v-btn>
						</template>
					</send-email-button>
					<send-message-button
						class="mb-5"
						:values="values"
						:sms="message.sms"
						v-if="message.sms"
					>
						<template v-slot:button="scope">
							<v-btn
								@click="scope.click"
								icon
							>
								<v-icon>mdi-cellphone-message</v-icon>
							</v-btn>
						</template>
					</send-message-button>
					<!-- <v-btn
						v-if="message.sms_template"
						@click="sendMessage(message.sms_template)"
						icon
					>
						<v-icon>mdi-cellphone-message</v-icon>
					</v-btn> -->
				</v-col>
			</v-row>
		</template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
import SendEmailButton from "@c/communication/SendEmailButton";
import SendMessageButton from "@c/communication/SendMessageButton";

export default {
	name: "SendAppointmentEmails",
	props: {
		user: { type: String },
		id: { type: String },
		appointmentParticipant: { type: String },
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		appointment(){
			return this.$store.state.appointments.data[this.id];
		},
		emails(){
			return this.$store.state.emails.messages;
		},
		messages() {
			let messages = [
				{
					name: "Confirmation",
					email: "appointment_confirmation"
				},
				{
					name: "Chase Confirmation",
					sms: "chase_confirmation",
					email: "chase_confirmation"
				},
				{
					name: "Consent",
					email: "consent_reminder",
				},
				{
					name: "Reminder",
					email: "appointment_reminder",
					sms: "appointment_reminder"
				},
				{
					name: "Chase Participant",
					sms: "chase_participant",
					email: "chase_participant",
				},
				{
					name: "Check Availability",
					sms: "check_availability",
				},
				{
					name: "Incentive Reminder",
					sms: "incentive_reminder",
				},
				{
					name: "Reschedule",
					sms: "reschedule",
				},
			];
			if (this.appointment.methodology == "video") {
				messages.push({
					name: "Video Link",
					email: "video_reminder"
				});
			}
			return messages;
		},
		values() {
			return {
				appointment_participant: this.appointmentParticipant,
				
			}
		}
	},
	components: {
		SendEmailButton,
		SendMessageButton,
		MwDialog,
	}
};
</script>
