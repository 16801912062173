<template>
	<v-sheet color="transparent" class="mt-4">
		
		<v-checkbox v-model="appointmentParticipant.show_to_client" label="Display to client" @change="(v) => update('show_to_client', v)"></v-checkbox>
		<v-autocomplete
			class="mt-5"
			label="Select User"
			:value="appointmentParticipant.user"
			:items="items"
			:return-object="false"
			clearable
			@input="(v) => update('user', v)"
			@change="(v) => update('user', v)"
			@focus="fetchParticipants"
		>
		</v-autocomplete>
		<v-autocomplete
			small-chips
			color="darkest"
			label="Status"
			:value="appointmentParticipant.appointment_status"
			@input="(v) => update('appointment_status', v)"
			:items="getItems('appointment_status')"
		></v-autocomplete>
		<v-autocomplete
			v-if="project"
			small-chips
			color="darkest"
			label="Participant Quota"
			:value="appointmentParticipant.quota"
			@input="(v) => update('quota', v)"
			:items="getItems('quota')"
		></v-autocomplete>
		<v-text-field
			v-for="field in project.custom_fields"
			:key="field"
			:value="appointmentParticipant[field]"
			:label="mwutils.prettyPrint(field)"
			@input="(v) => update(field, v)"
		></v-text-field>
		<v-card v-if="appointmentParticipant.client_comments" flat class="mb-2">
			<v-card-title>Client Comments</v-card-title>
			<v-card-text>
				<span>
					{{appointmentParticipant.client_comments}}
				</span>
			</v-card-text>
		</v-card>
		<v-card v-if="appointmentParticipant.participant_comments" flat class="mb-2">
			<v-card-title>Participant Comments</v-card-title>
			<v-card-text>
				<span>
					{{appointmentParticipant.participant_comments}}
				</span>
			</v-card-text>
		</v-card>
		<v-btn
			class="mb-2"
			target="_blank"
			link
			:href="`/users/${appointmentParticipant.user}`"
			block
			text outlined
			color="primary"
			>
			<v-icon class="pr-1"> mdi-account </v-icon>
			View User</v-btn
		>
		<select-response-for-appointment
			v-if="appointmentParticipant.user && project.screeners[0]"
			:value="appointmentParticipant.response"
			@input="v => update('response', v)"
			:screeners="project.screeners"
			:user="appointmentParticipant.user"
		></select-response-for-appointment>
		<send-appointment-communications
			v-if="appointmentParticipant.user"
			:user="appointmentParticipant.user"
			:appointment-participant="appointmentParticipant.id"
			:id="appointmentParticipant.appointment"
		></send-appointment-communications>
	</v-sheet>
</template>

<script>
import SendAppointmentCommunications from "@c/communication/SendAppointmentCommunications";
import AppointmentParticipant from "@/models/AppointmentParticipant";
import SelectResponseForAppointment from "@c/responses/SelectResponseForAppointment.vue";
export default {
	name: "EditAppointmentParticipant",
	props: {
		id: { type: String },
		prefill: { type: Object },
	},
	inject: ["projectId"],
	computed: {
		model() {
			return AppointmentParticipant;
		},
		project() {
			return this.$store.state.projects.data[this.projectId];
		},
		appointmentParticipant() {
			if (!this.id) {
				let appointmentParticipant = this.mwutils.getEmpty(this.model);
				for (var key in this.prefill) {
					appointmentParticipant[key] = this.prefill[key];
				}
			}
			return this.$store.state.appointmentParticipants.data[this.id];
		},
		users() {
			return this.$store.getters.getByKey(
				"users",
				"role",
				"participant",
				true
			);
		},
		items() {
			return this.mwutils.itemsArray(this.users);
		},
	},
	methods: {
		getItems(key) {
			let str = `${this.model[key].try_items}`;
			let try_items = this.mwutils.byString(this, str);			
			return try_items || this.model[key].items || [];

		},
		fetchParticipants() {
			this.$store.dispatch("users/fetchParticipants");
		},
		update(key, v) {
			const self = this;
			this.$store.dispatch("appointmentParticipants/patch", {
				id: this.id,
				[key]: v,
			}).then( () => {
				self.$root.$emit("showToast", "Saved");
			})
			
		},
	},
	components: {
		SelectResponseForAppointment,
		SendAppointmentCommunications
	},
};
</script>
